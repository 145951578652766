import * as React from "react"
import Layout from "../components/layout"
import Link from "gatsby-link"

const IndexPage = () => {
  const highlights = [
    {
      title:
        "SmartOp, une plateforme web de pilotage avec une vision métier du bloc opératoire",
      description:
        "<li>SmartOp est unique car elle regroupe les compétences de médecins anesthésistes-réanimateurs et d’ingénieurs autour d’un seul but : vous fournir l’outil le plus efficace. Elle répond aux réelles problématiques du quotidien d’un bloc opératoire à partir des véritables besoins métiers.</li><li>Fini la multiplication des fonctionnalités inadaptées, SmartOp vous propose des solutions pertinentes et faciles d’utilisation</li> ",
      logo:
        "loop.svg"
    },
    {
      title: "SmartOp  vous accompagne",
      description:
        "<li>SmartOp pense les solutions en fonction des besoins de chaque structure. Grâce à sa très grande configurabilité, SmartOp s’adapte à tout type de bloc. L’application retranscrit la réalité de votre bloc opératoire et vous permet un pilotage fluide et naturel.</li> <li>Quel que soit votre rôle au sein du bloc opératoire, l’interface personnalisée sera un outil de choix dans la gestion de vos tâches quotidiennes mais aussi sur l’expérience acquise au cours du temps.</li>",
      logo:
        "card.svg"
    },
    {
      title: "SmartOp  diffuse l’information",
      description:
        "<li>La plateforme centralise l’ensemble de la gestion des effectifs du personnel soignant au sein du bloc opératoire (chirurgien, MAR, IADE, IBODE, IDE, AS).</li> <li>Grâce à ce désilotage, Smartop devient la plateforme de référence pour piloter les besoins en ressources humaines.</li> <li>L’information est mise à disposition de l’ensemble des soignants pour éviter toute incompréhension.</li> <li>SmartOp exploite l’environnement mobile pour dynamiser la transmission de l’information et faire en sorte que les soignants soient mis au courant des modifications de programme en temps réel.</li> ",
      logo:
        "campaign.svg"
    },
    {
      title: "SmartOp  innove",
      description:
        "<li>SmartOp exploite la donnée de votre bloc opératoire pour en tirer de la valeur. Elle offre des fonctionnalités intelligentes pour simplifier le pilotage du bloc opératoire mais aussi optimiser les ressources humaines et matérielles à votre disposition. SmartOp vous accompagne dans la prise en main de ces outils pour vous en montrer la pertinence et vous laisse orienter leur utilisation.</li> ",
      logo:
        "tips_and_updates.svg"
    }
  ]
  return (
    <Layout>
      <div className="_container">
        <div className="header">
          <div className="header-text">
            <div className="title mg-b-50">
              SmartOp est la première plateforme web intelligente de pilotage du bloc opératoire.
            </div>
            <div className="subtitle mg-b-70">
              Pensée par des soignants pour des soignants, SmartOp vous accompagne dans la gestion de vos ressources
              pour une meilleure
              prise en charge de vos patients.
            </div>
            <div className="button-test">
              <a href="contact">
                Demander une démo
              </a>
            </div>
          </div>
          <div className="right">
            <div className="intro-video">
              <iframe src="https://player.vimeo.com/video/557631247?title=0&byline=0&portrait=0" width="640"
                      height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen>
              </iframe>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </div>
        </div>

        <div className="content-container">
          {highlights.map(({ title, description, logo }) =>
            <div className="content">
              <div className="content-img">
                <img src={logo} alt={logo} />
              </div>
              <div className="title">
                {title}
              </div>
              <div className="subtitle" dangerouslySetInnerHTML={{ __html: description }}>
              </div>
            </div>
          )}

          <div className="content-action">
            <Link to="/contact">
              <div className="button-test btn">
                <a>
                  Demander une démo
                </a>
              </div>
            </Link>
            <Link to="/produit">
              <div className="button-functionality btn">
                <a>
                  Découvrir les fonctionnalités
                </a>
              </div>
            </Link>
          </div>
        </div>

        <div className="trust-us-container">
          <div className="title">Ils nous font confiance</div>
          <div className="hospitals-container">
            <div className="center mg-r-96">
              <img className="logo mg-b-16" src="/hospital-cg-logo.svg" />
              <div className="mg-b-24">
                <img className="star1" src="/star.svg" />
                <img className="star2" src="/star.svg" />
                <img className="star3" src="/star.svg" />
                <img className="star4" src="/star.svg" />
                <img className="star5" src="/star.svg" />
              </div>
              <div className="_title mg-b-8">“Après une semaine d’utilisation, SmartOp est très agrèable à prendre en
                main. La facilité d’utilisation est top.”
              </div>
              <div className="_subtitle mg-b-24">Benjamin, Régulateur de bloc</div>
              <div className="_title mg-b-8">“C'est le jour et la nuit dans la gestion du bloc grâce à SmartOp.”</div>
              <div className="_subtitle">Vincent, Cadre de bloc</div>
            </div>
            <div className="center">
              <img className="logo mg-b-16" src="/hospital-larib-logo.svg" />
              <div className="mg-b-24">
                <img className="star1" src="/star.svg" />
                <img className="star2" src="/star.svg" />
                <img className="star3" src="/star.svg" />
                <img className="star4" src="/star.svg" />
                <img className="star5" src="/star.svg" />
              </div>
              <div className="_title mg-b-8">“L'application est fluide et complète et répond bien à la nécessité de
                changer l'approche des plannings dans l'hopital public."
              </div>
              <div className="_subtitle">Ambre, Médecin anesthésiste-réanimateur PH</div>
            </div>
          </div>

          <div className="margin-top-40">
            <div className="enterprise-container-grand">
            <div className="inter-div-grand">
                <div className="center">
                  <img className="logo_enterprise mg-rlb-16" src="/ch-niort.svg" />
                </div>
                <div className="center">
                  <img className="logo_enterprise mg-rlb-16" src="/ghicl-lille.svg" style={{height: '110px' }}/>
                </div>
              </div>
              <div className="inter-div-grand">

                <div className="center margin-bottom-45">
                  <img className="logo_enterprise" src="/polyclinique-saint-privat.svg" style={{height: '110px' }}/>
                  </div>
                  
                  <div className="center">
                    <img className="logo_enterprise mg-rlb-16" src="chu_grenoble.svg" />
                  </div>
              </div>
             
        
            </div>
            <div className="enterprise-container-grand">
              <div className="inter-div-grand">
                <div className="center">
                  <img className="logo_enterprise mg-rlb-16" src="/ile_de_france.svg" />
                </div>
                <div className="center">
                  <img className="logo_enterprise mg-rlb-16" src="/anap.svg" />
                </div>
              </div>


              <div className="inter-div-grand">
              <div className="center margin-bottom-45">
                  <img className="logo_enterprise" src="/ch-nord-2-sevres2.svg" style={{height: '110px' }}/>
                  </div>
                  
                  <div className="center">
                  <img className="logo_enterprise mg-rlb-16" src="/chal.svg" style={{height: '100px' }}/>
                </div>
              </div>
            </div>

            <div className="enterprise-container-grand">
              <div className="inter-div-grand">
                <div className="center">
                  <img className="logo_enterprise mg-rlb-16" src="/bpifrance.svg" />
                </div>
                <div className="center">
                  <img className="logo_enterprise mg-rlb-16" src="/resah.svg" />
                </div>
              </div>
              <div className="inter-div-grand">
                <div className="center">
                  <img className="logo_enterprise mg-rlb-16" src="/clinavenir.svg" />
                </div>
                <div className="center">
                  <img className="logo_enterprise mg-rlb-16" src="/hospital-princesse-grace.svg" style={{height: '110px' }}/>
                </div>
              </div>
            </div>

            <div className="enterprise-container-grand">
              <div className="inter-div-grand">
                <div className="center">
                  <img className="logo_enterprise mg-rlb-16" src="/ch_valenciennes.svg" style={{ height: '110px' }} />
                </div>
                <div className="center">
                  <img className="logo_enterprise mg-rlb-16" src="ramsay.svg" />
                </div>
    
              </div>
            </div>
            </div>

     


            <div className="enterprise-container">
              <div className="center">
                <img className="logo_enterprise mg-rlb-16" src="/hopitaux_paris.svg" />
              </div>
            </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
